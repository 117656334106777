import styled from 'styled-components';
import {IoIosArrowRoundBack} from 'react-icons/io';
import { COLORS } from "../../constants/constants";

export const LoginContainer = styled.div`
    padding: 10px;
`
export const BackIcon = styled(IoIosArrowRoundBack)`
    color: ${COLORS.BLACK};
    font-size: 50px;

    &:hover {
        color: ${COLORS.FIRST};
        transition: 0.2s ease-in-out;
    }
`

export const IconContainer = styled.div`

`

export const FormContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 89vh;
`

export const FormWrapper = styled.div`
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
`;

export const FormTitle = styled.h2`
    margin-bottom: 20px;
    color: ${COLORS.FIRST};
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const FormLabel = styled.label`
    margin-bottom: 8px;
    font-size: 14px;
    color: ${COLORS.SEVENTH};
    text-align: left;
`;

export const FormInput = styled.input`
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid ${COLORS.GRAYLIGHT};
    font-size: 16px;

    &:focus {
        border-color: ${COLORS.FIRST};
        outline: none;
    }
`;

export const FormButton = styled.button`
    padding: 12px 20px;
    border-radius: 12px;
    border: none;
    background-color: ${COLORS.SECOND};
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
        background-color: ${COLORS.FIRST};
        transform: scale(1.05); /* Subtle lift effect */
    }

    &:disabled {
        background-color: ${COLORS.SECOND}88; /* Faded when disabled */
        cursor: not-allowed;
    }

    .spin {
        animation: spin 1s linear infinite;
        font-size: 18px; /* Match spinner size to text */
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

export const ErrorContainer = styled.div`
    background: rgba(247, 156, 156, 0.5);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: ${COLORS.RED};
    padding: 12px 20px;
    border-radius: 12px;
    margin-bottom: 20px;
`;