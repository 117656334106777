import styled from 'styled-components';
import {IoIosArrowRoundBack} from 'react-icons/io';
import { COLORS } from "../../constants/constants";

export const UpdateProfileMainContainer = styled.div`
    background: linear-gradient(to bottom right,rgb(229, 248, 255),rgb(223, 255, 255),rgb(144, 172, 255));
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`

export const UpdateProfileContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    height : 84vh;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export const Input = styled.input`
    width: 200px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 1.2rem;
    width: 100%;
    max-width: 350px;

    &:focus {
        border-color: ${COLORS.FIRST};
        outline: none;
    }
`;

export const Title = styled.h2`
    margin-bottom: 20px;
    color: ${COLORS.FIRST};
`;

export const UpdateButton = styled.div`
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: ${COLORS.SECOND};
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    margin-top: 10px;

    &:hover {
        background-color: ${COLORS.FIRST};
    }
`

export const Error = styled.span`
  font-size: 1.2rem;
  padding-left: 5px;
  color: ${COLORS.DANGER};
  border-radius: 10px;
  background-color: ${COLORS.DANGER_BACKGROUND};
  padding: 10px;
  margin-bottom: 10px;
`;

export const SubContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
`

export const Space = styled.div`
    margin-top: 50px;
`

