import React, { useEffect, useState } from 'react';
import Navbar from '../../components/NavBar';
import { DashboardContainer, DashboardContent, DashboardSubContainer, Img } from './DashboardElements';
import Sidebar from '../../components/Sidebar';
import Modal from '../../components/Modal';
import Usage from '../../components/Usage';
import Home from '../../components/Home';
import { useSelector, useDispatch } from 'react-redux';
import AllUsage from "../../components/AllUsage"
import Stats from "../../components/Stats"
import Users from "../../components/Users"
import Settings from "../../components/Settings"
import { clearStats, setStats } from '../../slices/statsSlice';
import { CONNECTIONS} from '../../constants/constants';
import { useNavigate } from 'react-router';


const Dashboard = () => {
    const {id, token, type} = useSelector((state) => state.credentials);
    const [isOpen, setIsOpen] = useState(false);
    const [sidebar, setSideBar] = useState(true);
    const [pageOption, setPageOption] = useState(1);
    const {stats} = useSelector((state) => state.stats);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        if (token === "" || token === null) {
            navigate('/login'); 
        }  
    },[])

    useEffect(() => {
        if (type && (type === "admingamtooswater" || type === "supergamtooswater") && stats === null) {
            getAllUsage(); 
        }  
    },[])

    const refreshStats = () => {
        dispatch(clearStats());
        if (type && (type === "admingamtooswater" || type === "supergamtooswater")) {
            getAllUsage(); 
        } 
    }

    const getAllUsage = async () => {
        setLoading(true);
        setError(null);
    
        try {
            const url = `${CONNECTIONS.SERVICE_URL_UMA}/api/admin/timeseries/today`;
    
            const payload = {
                CredentialId: id,
            };
    
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                if (response.status === 409) {
                    setError("User already exists");
                } else if (response.status === 500) {
                    setError("Server error occurred");
                } else {
                    setError(`Unexpected error: ${response.status}`);
                }
                return;
            }
    
            const responseData = await response.json();
            dispatch(setStats(responseData));
            
        } catch (err) {
            setError(err.message || "An unexpected error occurred");
        } finally {
            setLoading(false); // Ensure loading ends
        }
    };

    return (
        <DashboardContainer>
            <Modal isOpen={isOpen} toggle={toggle}  pageOption={pageOption} setPageOption={setPageOption}/>
            <Navbar toggle={toggle} option={1}/>
            <Sidebar sidebar={sidebar} setSideBar={setSideBar} pageOption={pageOption} setPageOption={setPageOption}/>
            <DashboardContent sidebar={sidebar}>
                {type && (type === "admingamtooswater" || type === "supergamtooswater") && (
                    <DashboardSubContainer>
                        {pageOption === 1 && (
                            <Home setPageOption={setPageOption} handleReloadStats={refreshStats}/>
                        )}
                        {/* {pageOption === 2 && (
                            <AllUsage/>
                        )} */}
                        {pageOption === 3 && (
                            <Stats handleReloadStats={refreshStats}/>
                        )}
                        {pageOption === 4 && (
                            <Users/>
                        )}
                        {pageOption === 5 && (
                            <Settings/>
                        )}
                    </DashboardSubContainer>
                )}
            </DashboardContent>
        </DashboardContainer>
    )
};

export default Dashboard;
