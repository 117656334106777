import React, { useEffect, useState } from 'react';
import { Button, ButtonContainer, ButtonText, Content, HomeContainer, HomeSubContainer, Title, WeatherContainerColumn, WeatherContainerRow, WeatherSubContainer, WaterUsageContainer, WaterUsageValue, ReloadContainer, ReloadButton } from './HomeElements';
import { useSelector } from 'react-redux';
import { IoIosStats } from "react-icons/io";
import { FaUsers } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import * as Icons from "react-icons/fa";
import Weather from '../WeatherData';
import DamLevel from '../DamLevel';
import { FaSpinner } from 'react-icons/fa';
import { COLORS } from "../../constants/constants";
import axios from 'axios';

const Home = ({setPageOption, handleReloadStats}) => {
    const {id, token, email, name, surname, phone, type} = useSelector((state) => state.credentials);
    const {stats} = useSelector((state) => state.stats)

    const handleOnClick = (option) => {
        setPageOption(option)
    }

    return (
        <HomeContainer>
            {type && (type === "admingamtooswater" || type === "supergamtooswater") && (
                <Content>
                    <WeatherSubContainer>
                        <WeatherContainerRow style={{ marginBottom: "20px" }}>
                            <WaterUsageContainer>
                                <Title>Water Usage Today</Title>
                                <ReloadContainer>
                                    <ReloadButton onClick={handleReloadStats}>
                                        Reload
                                    </ReloadButton>
                                </ReloadContainer>
                                {stats ? (
                                    <WaterUsageValue>
                                        {stats.totalvolume.toFixed(2)} m³
                                    </WaterUsageValue>
                                ):(
                                    <div>
                                        <FaSpinner className="spin" style={{ color: COLORS.FIRST, fontSize: '24px' }} />
                                    </div>
                                )}
                            </WaterUsageContainer>
                        </WeatherContainerRow>
                        <WeatherContainerRow>
                            <Title></Title>
                        </WeatherContainerRow>
                    </WeatherSubContainer>
                    <WeatherContainerColumn>
                        <Title>Weather</Title>
                        <Weather city="Kouga Dam" latitude={-33.7406} longitude={24.5877} />
                        <Weather city="Patensie" latitude={-33.7597} longitude={24.8044} />
                        <Weather city="Loerie" latitude={-33.7278} longitude={24.5361} />
                        <Weather city="Mondplaas" latitude={-33.7474} longitude={24.4404} />
                    </WeatherContainerColumn>
                    {/* <WeatherContainerColumn>
                        <Title>Dam Levels</Title>
                        <DamLevel title="Kouga Dam" damLevel={84.72} />
                        <DamLevel title="Loerie Dam" damLevel={89.33} />
                        <DamLevel title="Churchill Dam" damLevel={88.98} />
                        <DamLevel title="Impofu Dam" damLevel={56.32} />
                    </WeatherContainerColumn> */}
                </Content>
            )}
            
        </HomeContainer>
    )
};

export default Home;