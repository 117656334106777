import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const StatsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    min-height: 84.8vh;
`

export const StatsSubContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: ${COLORS.LIGHTWHITE};
    background-color: ${COLORS.FIRST};
    padding: 10px;

    &:hover {
        background-color: ${COLORS.SECOND};
        cursor: pointer;
    } 
`

export const FiltersContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 30px;
`

export const FilterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
`

export const FilterTitel = styled.div`
    font-weight: bold;
`

export const Space = styled.div`
    width: 10px;
`

export const ReloadContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 70%;
`

export const ReloadButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: ${COLORS.LIGHTWHITE};
    background-color: ${COLORS.FIRST};
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    &:hover {
        background-color: ${COLORS.SECOND};
        cursor: pointer;
    }
`

export const QuickStatsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    margin-top: 20px;
    color: ${COLORS.FIRST};
`

export const QuickStatsSpan = styled.span`
    font-size: 18px;
    font-weight: bold;
`

export const GraphTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
`
export const GraphTitle = styled.span`
    font-size: 20px;
    font-weight: bold;
`

export const BarGraphContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 500px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    margin-bottom: 50px;
`;

export const TableMainContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    margin-top: 20px;
    color: ${COLORS.FIRST};
`

export const TableContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 0.6);
`;

export const TableTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1.5rem;
    color: ${COLORS.FIRST};
`;

export const TableSubTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1rem;
    color: ${COLORS.FIRST};
`;

export const StatsTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 40px;
    color: ${COLORS.FIRST};
`