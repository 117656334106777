import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stats: null,
};

const statsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    setStats(state, action) {
        state.stats = action.payload;
    },
    clearStats(state) {
        state.stats = null;
    },
  },
});

export const { setStats, clearStats } = statsSlice.actions;

export default statsSlice.reducer;
