import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const HomeContainer = styled.div`

`
export const Content = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
`

export const HomeSubContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 50%;
`

export const Title = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 30px;
    font-weight: bold;
    margin-top: 10px;
    color: ${COLORS.FIRST};
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px;
`

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: ${COLORS.BLACK};
    background-color: ${COLORS.WHITE};
    padding: 30px;
    width: 100%;
    max-width: 500px;
    font-weight: bold;
    font-size: 24px;

    &:hover {
        background-color: ${COLORS.FIRST};
        color: ${COLORS.LIGHTWHITE};
        cursor: pointer;
    }
`

export const ButtonText = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-weight: bold;
`

export const WeatherContainerColumn = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    height: 85vh;
    width: 40%;
    margin-left: 20px;
`

export const WeatherContainerRow = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 41.5vh;
    margin-left: 20px;
    margin-right: 20px;
`

export const WeatherSubContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 57%;
`

export const WaterUsageContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

export const WaterUsageValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 50px;
    color: ${COLORS.SECOND};
    margin-top: 50px;
`;

export const ReloadContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 70%;
`

export const ReloadButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: ${COLORS.LIGHTWHITE};
    background-color: ${COLORS.FIRST};
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    &:hover {
        background-color: ${COLORS.SECOND};
        cursor: pointer;
    }
`
