import styled, { keyframes } from 'styled-components';
import { COLORS } from "../../constants/constants";

// Container for the entire Dam Level component
export const DamLevelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

// Card styling for the Dam Level
export const DamLevelCard = styled.div`
  width: 250px;
  text-align: center;

  h3 {
    font-size: 1.5rem;
    color: ${COLORS.FIRST};
    margin-bottom: 10px;
  }
`;

// Details container for the Dam Level data
export const DamLevelDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #333;
  position: relative;
`;

// Animation for the water level increase
const waterAnimation = keyframes`
  0% {
    height: 0%;
  }
  100% {
    height: ${(props) => props.level}%;
  }
`;

// Container for the water level
export const WaterLevelContainer = styled.div`
  position: relative;
  width: 80px;
  height: 100px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

// Water level animation styled component
export const WaterLevel = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color:${COLORS.FIRST};
  animation: ${waterAnimation} 2s ease-out forwards;
  height: ${(props) => props.level}%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  
  span {
    position: absolute;
    font-size: 1.2rem;
  }
`;

export const IconWrapper = styled.div`
  display: inline-block;
  margin-right: 10px;
  color: #4caf50;
`;
