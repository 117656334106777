import { configureStore } from '@reduxjs/toolkit';
import credentialsReducer from './slices/credentialsSlice';
import statsReducer from './slices/statsSlice';

const store = configureStore({
  reducer: {
    credentials: credentialsReducer,
    stats: statsReducer,
  },
});

export default store;
