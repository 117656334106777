import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const EditUserContainer = styled.div`
    background: linear-gradient(to bottom right,rgb(229, 248, 255),rgb(223, 255, 255),rgb(144, 172, 255));
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`

export const EditUserContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    height : 84vh;
    margin-left: 20px;
    margin-right: 20px;
`

export const EditUserSubContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: ${COLORS.LIGHTWHITE};
    background-color: ${COLORS.FIRST};
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    &:hover {
        background-color: ${COLORS.SECOND};
        cursor: pointer;
    }
    
`

export const Title = styled.h1`
`

export const SubTitle = styled.h2`
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export const Input = styled.input`
    width: 200px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 1.2rem;
    width: 100%;
    max-width: 350px;

    &:focus {
        border-color: ${COLORS.FIRST};
        outline: none;
    }
`;

export const Error = styled.span`
  font-size: 1.2rem;
  padding-left: 5px;
  color: ${COLORS.DANGER};
  border-radius: 10px;
  background-color: ${COLORS.DANGER_BACKGROUND};
  padding: 10px;
  margin-bottom: 10px;
`;

export const EditUserInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 30px;
    width: 100%;
    background-color: ${COLORS.GRAYLIGHT};
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
`

export const Label = styled.div`
    font-size: 16px;
    font-weight: bold;
`

export const EditUserInfoTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 40px;

`

export const Space = styled.div`
    margin-top: 50px;
`

export const SpaceContent = styled.div`
    margin-top: 50px;
`