import React, { useEffect, useState } from 'react';
import { BarGraphContainer, FilterContainer, FiltersContainer, FilterTitel, GraphTitle, GraphTitleContainer, QuickStatsContainer, QuickStatsSpan, Space, StatsContainer, StatsSubContainer, StatsTitle, TableContainer, TableSubTitle, TableTitle, TableMainContainer, ReloadContainer, ReloadButton } from './StatsElements';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CONNECTIONS} from '../../constants/constants';
import { useSelector } from 'react-redux';
import BarChart from '../BarChart';
import BarChartLines from '../BarChartLines';

const Stats = ({handleReloadStats}) => {
    const { id, token } = useSelector((state) => state.credentials);

    const [allUsage, setAllUsage] = useState([]);
    const [lineUsage, setLineUsage] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState({ value: '2', label: 'Last Week' });
    const [selectedLine, setSelectedLine] = useState({ value: 'all', label: 'All' });
    const [totalVolume, setTotalVolume] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [xAxisMin, setXAxisMin] = useState();
    const [xAxisMax, setXAxisMax] = useState();
    const [totalVolumesUsers, setTotalVolumesUsers] = useState();
    const [totalVolumesUsersTableData, setTotalVolumesUsersTableData] = useState();

    const {stats} = useSelector((state) => state.stats);

    const getHour = (datetime) => {
        const date = new Date(datetime);
        date.setHours(date.getUTCHours() + 2);
        const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        return timeString;
    };

    const getHourValue = (datetime) => {
        const date = new Date(datetime);
        date.setHours(date.getUTCHours() + 2);
        const timeString = date.toLocaleTimeString([], { hour: '2-digit', hour12: false });
        return parseInt(timeString);
    };

    const getYear = (datetime) => {
        const date = new Date(datetime);
        return date.getUTCFullYear();
    };

    const getMonth = (datetime) => {
        const date = new Date(datetime);
        return (date.getUTCMonth() + 1).toString().padStart(2, '0');
    };

    const getDate = (datetime) => {
        const date = new Date(datetime);
        return date.getUTCDate().toString().padStart(2, '0');
    };

    const isInNextWeek = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        
        // Set time to 0 for both dates for an accurate day comparison
        now.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
    
        // Calculate the difference in milliseconds
        const diffTime = date - now;
    
        // Convert milliseconds to days
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
    
        // Check if the date is within the next 7 days
        return diffDays >= 0 && diffDays < 7;
    };

    const isInLastDay = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
    
        // Calculate the difference in milliseconds
        const diffTime = now - date;
    
        // Convert milliseconds to days
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
    
        // Check if the difference is less than or equal to 1 day
        return diffDays <= 1 && diffDays >= 0;
    };

    const isInToday = (dateString) => {
        const date = new Date(dateString);
        const today = new Date();
    
        // Start of today (midnight)
        const startOfToday = new Date(today);
        startOfToday.setHours(0, 0, 0, 0);
    
        // End of today (11:59:59.999 PM)
        const endOfToday = new Date(today);
        endOfToday.setHours(23, 59, 59, 999);
    
        // Check if the date falls within today's range
        return date >= startOfToday && date <= endOfToday;
    };
    
    const isInLastWeek = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
    
        // Calculate the difference in milliseconds
        const diffTime = now - date;
    
        // Convert milliseconds to days
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
    
        // Check if the difference is less than or equal to 7 days
        return diffDays <= 7 && diffDays >= 0;
    };
    
    const isInLastMonth = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
    
        // Get the current date minus 1 month
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
    
        // Check if the date is after the calculated last month
        return date >= lastMonth && date <= now;
    };
    
    const isInLastSixMonths = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
    
        // Get the current date minus 6 months
        const lastSixMonths = new Date();
        lastSixMonths.setMonth(lastSixMonths.getMonth() - 6);
    
        // Check if the date is after the calculated last six months
        return date >= lastSixMonths  && date <= now;
    };
    
    const isInLastYear = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
    
        // Get the current date minus 1 year
        const lastYear = new Date();
        lastYear.setFullYear(lastYear.getFullYear() - 1);
    
        // Check if the date is after the calculated last year
        return date >= lastYear  && date <= now;
    };
    
    // Generate the 24-hour time range for the x-axis (00:00 to 23:00)
    const generate24HourRange = () => {
        const hours = [];
        for (let i = 0; i < 24; i++) {
            const hourString = i.toString().padStart(2, '0') + ":00";
            hours.push(hourString);
        }
        return hours;
    };

    const mergeUsageWithFullDay = (usageData) => {
        const fullDay = generate24HourRange();
        const usageMap = usageData.reduce((acc, item) => {
            acc[item.hour] = item;
            return acc;
        }, {});

        return fullDay.map((hour) => {
            return usageMap[hour] ? usageMap[hour] : { hour, volume: 0 };
        });
    };

    const getNextDay = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        return date.toISOString();
    };

    const formattedDate = (year, month, date, hour) => {
        const hour_int = parseInt(hour, 10); // Ensure hour is an integer
        const date_int = parseInt(date, 10); // Ensure date is an integer
        const month_int = parseInt(month, 10) - 1; // Adjust month to be zero-based and ensure it's an integer
        const year_int = parseInt(year, 10); // Ensure year is an integer

        // Construct the Date object
        const formattedDate = new Date(year_int, month_int, date_int, hour_int);
        return formattedDate
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options); // Use 'en-GB' for the desired format
    }

    const formatToISO = (year, month, date, hour) => {
        const hourInt = parseInt(hour, 10); // Ensure hour is an integer
        const dateInt = parseInt(date, 10); // Ensure date is an integer
        const monthInt = parseInt(month, 10) - 1; // Adjust month to zero-based
        const yearInt = parseInt(year, 10); // Ensure year is an integer
    
        // Create the Date object
        const dateObject = new Date(yearInt, monthInt, dateInt, hourInt);
    
        // Return ISO string format
        return dateObject.toISOString();
    };


    useEffect(() => {
        const fetchUserInfo = async () => {
            if (!totalVolumesUsers || totalVolumesUsers.length === 0) return;
    
            const keys = totalVolumesUsers.map((entry) => entry.username); // Extract keys if array
    
            try {
                const url = `${CONNECTIONS.SERVICE_URL_CA}/api/getUsersInfo`;
    
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(keys),
                });
    
                if (!response.ok) {
                    if (response.status === 409) setError("Conflict: User already exists.");
                    if (response.status === 500) setError("Server Error.");
                    return;
                }
    
                const data = await response.json();
    
                // Match the fetched user info with the volume data
                const volumeUsersTableData = data.map((item) => ({
                    username: `${item.name} ${item.surname}`,
                    volume: totalVolumesUsers.find((user) => user.username === item.credentialId)?.volume || 0,
                }));
    
                setTotalVolumesUsersTableData(volumeUsersTableData);
            } catch (err) {
                setError(`Failed to fetch user info: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };
    
        fetchUserInfo();
    }, [totalVolumesUsers]);

    useEffect(() => {
        const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;

        if (!stats) return;
    
        // Bar Chart Data (Hourly usage)
        const barChartData = Object.entries(stats.data).map(([key, value]) => ({
            date: new Date(new Date(key).getTime() + twoHoursInMilliseconds).toISOString(), // Add 2 hours
            volume: value,
        }));
        setAllUsage(barChartData);


        const barLineChartData = Object.entries(stats.lines).map(([key, value]) => ({
            line: key,
            volume: value,
        }));
        setLineUsage(barLineChartData);

        console.log(barLineChartData)
    
        // Table Data (Volume by Date)
        const dateTableData = Object.entries(stats.date).map(([key, value]) => ({
            date: key,
            totalVolume: value,
        }));
        setTableData(dateTableData);
    
        // User Table Data (Volume by User)
        const userTableData = Object.entries(stats.users).map(([key, value]) => ({
            username: key, // Replace this with a lookup if you have user names elsewhere
            volume: value,
        }));
        setTotalVolumesUsers(userTableData);

        // Add 2 hours to xAxisMin
        setXAxisMin(new Date(new Date(barChartData[0].date).getTime() + twoHoursInMilliseconds).toISOString());

        // Add 2 hours to xAxisMax
        setXAxisMax(new Date(new Date(barChartData[barChartData.length - 1].date).getTime() + twoHoursInMilliseconds).toISOString());

    }, [stats]);

    // Helper function to check the selected time period
    const checkTimePeriod = (periodValue, date) => {
        if (periodValue === "0" && isInNextWeek(date)) return true;
        if (periodValue === "1" && isInToday(date)) return true;
        if (periodValue === "2" && isInLastWeek(date)) return true;
        if (periodValue === "3" && isInLastMonth(date)) return true;
        if (periodValue === "4" && isInLastSixMonths(date)) return true;
        if (periodValue === "5" && isInLastYear(date)) return true;
        if (periodValue === "6") return true;
        return false;
    };

    return (
        <StatsContainer>
            <StatsTitle>
                Water Usage Today
            </StatsTitle>
            {/* <FiltersContainer>
                <FilterContainer>
                    <FilterTitel>Time Period</FilterTitel>
                    <Select 
                        value={selectedTimePeriod}
                        onChange={(value) => setSelectedTimePeriod(value)} 
                        options={timePeriodOptions} 
                        styles={customDropdownStyles} 
                    />
                </FilterContainer>
                <Space />
                <FilterContainer>
                    <FilterTitel>Line</FilterTitel>
                    <Select 
                        value={selectedLine}
                        onChange={(value) => setSelectedLine(value)} 
                        options={usageSectionOptionsFilter} 
                        styles={customDropdownStyles} 
                    />
                </FilterContainer>
            </FiltersContainer> */}
            {stats && (
                <StatsSubContainer>
                    <ReloadContainer>
                        <ReloadButton onClick={handleReloadStats}>
                            Reload
                        </ReloadButton>
                    </ReloadContainer>
                    <QuickStatsContainer>
                        <QuickStatsSpan>
                            Total Volume: {stats.totalvolume.toFixed(2)} m³
                        </QuickStatsSpan>
                    </QuickStatsContainer>
                    {allUsage && allUsage.length > 0 && (
                        <BarGraphContainer>
                            <BarChart data={allUsage} heigth={"500px"} xAxisStart={xAxisMin} xAxisEnd={xAxisMax}/>
                        </BarGraphContainer>
                    )}

                    {lineUsage && lineUsage.length > 0 && (
                        <BarGraphContainer>
                            <BarChartLines data={lineUsage} height={'500px'} xAxisStart={xAxisMin} xAxisEnd={xAxisMax}/>
                        </BarGraphContainer>
                    )}
                    
                    <TableMainContainer>
                        <TableTitle>
                            Volume by Date
                        </TableTitle>
                        {tableData && tableData.length > 0 && (
                            <TableContainer>
                                <table style={{ width: '100%', borderCollapse: 'collapse', borderRadius: '10px', overflow: 'hidden', border: '1px solid transparent' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Total Volume</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((entry, index) => (
                                            <tr key={index}>
                                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{formatDate(entry.date)}</td>
                                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{entry.totalVolume.toFixed(2)} m³</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </TableContainer>
                            
                        )}
                    </TableMainContainer>

                    <TableMainContainer>
                        <TableTitle>
                            Volume by User
                        </TableTitle>
                        <TableSubTitle>
                            (This is over the whole time period selected)
                        </TableSubTitle>
                        {totalVolumesUsersTableData && totalVolumesUsersTableData.length > 0 && (
                            <TableContainer>
                                <table style={{ width: '100%', borderCollapse: 'collapse', borderRadius: '10px', overflow: 'hidden', border: '1px solid transparent' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>UserName</th>
                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Total Volume</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {totalVolumesUsersTableData.map((entry, index) => (
                                            <tr key={index}>
                                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{entry.username}</td>
                                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{entry.volume.toFixed(2)} m³</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </TableContainer>
                        )}
                    </TableMainContainer>
                </StatsSubContainer>
            )}
            
        </StatsContainer>
    );
};

export default Stats;
