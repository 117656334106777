import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const GroupButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ButtonRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.BLACK};
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: bold;
  cursor: pointer;

  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  &:hover {
      background-color: ${COLORS.SECOND};
      border: 1px solid ${COLORS.SECOND};
      cursor: pointer;
  }
`;

export const ButtonMiddle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.BLACK};
  padding: 10px;
  font-weight: bold;
  cursor: pointer;

  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  &:hover {
      background-color: ${COLORS.SECOND};
      border: 1px solid ${COLORS.SECOND};
      cursor: pointer;
  }
`;

export const ButtonLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.BLACK};
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: bold;
  cursor: pointer;

  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  

  &:hover {
      background-color: ${COLORS.SECOND};
      border: 1px solid ${COLORS.SECOND};
      cursor: pointer;
  }
`;

export const ButtonRightActive = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.FIRST};
  background-color: ${COLORS.FIRST};
  color: ${COLORS.LIGHTWHITE};
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

export const ButtonMiddleActive = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.FIRST};
  background-color: ${COLORS.FIRST};
  color: ${COLORS.LIGHTWHITE};
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

export const ButtonLeftActive = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.FIRST};
  background-color: ${COLORS.FIRST};
  color: ${COLORS.LIGHTWHITE};
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;