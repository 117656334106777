import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';
import { COLORS } from "../../constants/constants";

export const Nav = styled.nav`
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    height: 80px;
    display: flex;
    justify-content: center;  /* Centering horizontally */
    align-items: center;  /* Centering vertically */
    font-size: 1rem;
    position: sticky;
    top: 20px; /* Detached from the top */
    margin: 0 20px; /* Adds space from the sides */
    border-radius: 20px; /* Rounded corners */
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;


export const NavBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 24px;
`


export const NavLogo = styled(LinkR)`
    display: flex;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
`
export const Title = styled.div`
    color: ${COLORS.FIRST};
    font-weight: 900;
    font-size: 30px;
`

export const Img = styled.img`
    height: 60px;
    width: auto;
    margin: 0;
    padding-right: 0;
    display: block;
`
export const MobileIcon = styled.div`
    display:none;

    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 1.8rem;
        cursor: pointer;
        color: ${COLORS.LIGHTWHITE};
    }

    &:hover {
        color: ${COLORS.BLACK};
        transition: 0.2s ease-in-out;
    }
`

export const NavMenu = styled.ul`
    display:flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: 22px;

    @media screen and (max-width: 768px){
        display: none;
    }
`

export const NavItem = styled.li`
    height: 80px;
`

export const NavLinks = styled(LinkS)`
    color: ${COLORS.LIGHTWHITE};
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-weight: bold;
    
    &.active {
        border-bottom: 3px solid ${COLORS.BLACK};
    }

    &:hover {
        color: ${COLORS.BLACK};
        transition: 0.2s ease-in-out;
    }
`

export const NavUserName = styled.div`
    display:flex;
    justify-content:flex-end;
    align-items:center;
    flex-direction: row;
    color: ${COLORS.FIRST};
    
    @media screen and (max-width: 768px) {
        margin-right: 10px;
    }
`

export const Icon = styled.li`
    margin-right: 10px;
    display:flex;
    color: ${COLORS.FIRST};
`

export const NavRightContainer = styled.div`
    display:flex;
    justify-content:flex-end;
    align-items:center;
    flex-direction: row;
`
