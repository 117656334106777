import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const SettingsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    min-height : 84.9vh;
`

export const Title = styled.h2`
    margin-top: 20px;
    margin-bottom: 20px;
    color: ${COLORS.FIRST};
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
`

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    width: 100%;
    max-width: 500px;

    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

    &:hover {
        background-color: ${COLORS.FIRST};
        color: ${COLORS.LIGHTWHITE};
        cursor: pointer;
    }
    
`