import * as Icons from "react-icons/fa";
import { RiAuctionFill } from "react-icons/ri";
import { GiRifle } from "react-icons/gi";
import { IoIosStats } from "react-icons/io";
import { FaUsers } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";

export const COLORS = {
    FIRST: "#1E73BE",
    SECOND: "#2C95D2",
    THIRD: "#3379FC",
    FORTH: "#8FCEEF",
    FIFTH: "#343A40",
    SIXTH: "#222734",
    SEVENTH: "#333333",
    WHITE: "#F3F4F8",
    LIGHTWHITE: "#FAFAFC",
    GRAY: "#83829A",
    GRAY2: "#C1C0C8",
    GRAYLIGHT: "#EAEAEA",
    BLACK: "#000000",
    BLUE: "#0096FF",
    LIGHTBLUE: "#ADD8E6",
    CYAN: "#00FFFF",
    DARKBLUE: "#00008B",
    RED: "#FF0000",
    DARKRED: "#8B0000",
    SUCCESS: "#28a745",
    SUCCESS_BACKGROUND: "#d4edda",
    DANGER: "#dc3545",
    DANGER_LIGTER: "#dc3000",
    DANGER_BACKGROUND: "#f8d7da",

};

export const CONNECTIONS = {
    // SERVICE_URL_CA : "http://192.168.3.19:5001",
    // SERVICE_URL_UA : "http://192.168.3.19:5002",
    // SERVICE_URL_UAV2 : "http://192.168.3.19:5003",
    // SERVICE_URL_USA : "http://192.168.3.19:5004",
    // SERVICE_URL_UMA : "http://192.168.3.19:5005",
    SERVICE_URL_CA : "https://eendagopnreendag.com/ca",
    SERVICE_URL_UA : "https://eendagopnreendag.com/ua",
    SERVICE_URL_UAV2 : "https://eendagopnreendag.com/uav2",
    SERVICE_URL_USA : "https://eendagopnreendag.com/usa",
    SERVICE_URL_UMA : "https://eendagopnreendag.com/uma",
}

export const navItems = [
  {
    id: 1,
    title: "Home",
    icon: <Icons.FaHome />,
  },
  {
    id: 2,
    title: "Usage",
    icon: <Icons.FaHandHoldingWater />,
  },
  {
    id: 3,
    title: "Settings",
    icon: <IoMdSettings />,
  },
];

export const navItemsAdmin = [
  {
    id: 1,
    title: "Home",
    icon: <Icons.FaHome />,
  },
  // {
  //   id: 2,
  //   title: "All Usage",
  //   icon: <Icons.FaHandHoldingWater />,
  // },
  {
    id: 3,
    title: "Stats",
    icon: <IoIosStats />,
  },
  {
    id: 4,
    title: "Users",
    icon: <FaUsers />,
  },
  {
    id: 5,
    title: "Settings",
    icon: <IoMdSettings />,
  },
];

export const groupButtonsUsage = [
  {
    id: 0,
    title: "My Usage",
    icon: <></>,
  },
  {
    id: 1,
    title: "Add Usage",
    icon: <Icons.FaPlus />,
  },
];

export const groupButtonsUsers = [
  {
    id: 0,
    title: "All Users",
    icon: <></>,
  },
  {
    id: 1,
    title: "Add User",
    icon: <Icons.FaPlus />,
  },
];

export const groupButtonsEditUser = [
  {
    id: 0,
    title: "Update User Info",
    icon: <></>,
  },
  {
    id: 1,
    title: "Update Password",
    icon: <></>,
  },
];

export const usageTypeOptions = [
  { value: 'dam', label: 'Dam' },
  { value: 'pivot', label: 'Pivot' },
];

export const usageSectionOptions = [
  { value: 'l93', label: 'Line 93' },
  { value: 'l94', label: 'Line 94' },
  { value: 'l95', label: 'Line 95' },
  { value: 'l96', label: 'Line 96' },
  { value: 'l97', label: 'Line 97' },
  { value: 'l98', label: 'Line 98' },
  { value: 'l99', label: 'Line 99' },
  { value: 'l100', label: 'Line 100' },
  { value: 'l101', label: 'Line 101' },
  { value: 'l102', label: 'Line 102' },
  { value: 'l103', label: 'Line 103' },
  { value: 'l104', label: 'Line 104' },
  { value: 'l105', label: 'Line 105' },
  { value: 'l106', label: 'Line 106' },
  { value: 'l107', label: 'Line 107' },
];

export const usageTimeOptions = [
  { value: '0', label: '00:00' },
  { value: '1', label: '01:00' },
  { value: '2', label: '02:00' },
  { value: '3', label: '03:00' },
  { value: '4', label: '04:00' },
  { value: '5', label: '05:00' },
  { value: '6', label: '06:00' },
  { value: '7', label: '07:00' },
  { value: '8', label: '08:00' },
  { value: '9', label: '09:00' },
  { value: '10', label: '10:00' },
  { value: '11', label: '11:00' },
  { value: '12', label: '12:00' },
  { value: '13', label: '13:00' },
  { value: '14', label: '14:00' },
  { value: '15', label: '15:00' },
  { value: '16', label: '16:00' },
  { value: '17', label: '17:00' },
  { value: '18', label: '18:00' },
  { value: '19', label: '19:00' },
  { value: '20', label: '20:00' },
  { value: '21', label: '21:00' },
  { value: '22', label: '22:00' },
  { value: '23', label: '23:00' },
];

export const timePeriodOptions = [
  { value: '0', label: 'Next Week' },
  { value: '1', label: 'Today' },
  { value: '2', label: 'Last Week' },
  { value: '3', label: 'Last Month' },
  { value: '4', label: 'Last Six Months' },
  { value: '5', label: 'Last Year' },
  { value: '6', label: 'All Time' },
];

export const usageSectionOptionsFilter = [
  { value: 'all', label: 'All' },
  { value: 'l93', label: 'Line 93' },
  { value: 'l94', label: 'Line 94' },
  { value: 'l95', label: 'Line 95' },
  { value: 'l96', label: 'Line 96' },
  { value: 'l97', label: 'Line 97' },
  { value: 'l98', label: 'Line 98' },
  { value: 'l99', label: 'Line 99' },
  { value: 'l100', label: 'Line 100' },
  { value: 'l101', label: 'Line 101' },
  { value: 'l102', label: 'Line 102' },
  { value: 'l103', label: 'Line 103' },
  { value: 'l104', label: 'Line 104' },
  { value: 'l105', label: 'Line 105' },
  { value: 'l106', label: 'Line 106' },
  { value: 'l107', label: 'Line 107' },
];

export const customDropdownStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: COLORS.LIGHTWHITE,
    borderRadius: '10px',
    fontWeight: 'bold',
    width: '300px',
    
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? COLORS.SECOND : COLORS.LIGHTWHITE,
    color: state.isSelected ? 'white' : 'black',
    borderRadius: '10px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '10px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

export const customDropdownTimeStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: COLORS.LIGHTWHITE,
    borderRadius: '10px',
    fontWeight: 'bold',
    width: '120px',
    
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? COLORS.SECOND : COLORS.LIGHTWHITE,
    color: state.isSelected ? 'white' : 'black',
    borderRadius: '10px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '10px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

export const weatherCodeDescriptions = {
  0: "Clear sky",
  1: "Mainly clear",
  2: "Partly cloudy",
  3: "Overcast",
  45: "Fog",
  48: "Depositing rime fog",
  51: "Light drizzle",
  53: "Moderate drizzle",
  55: "Dense drizzle",
  61: "Slight rain",
  63: "Moderate rain",
  65: "Heavy rain",
  71: "Slight snowfall",
  73: "Moderate snowfall",
  75: "Heavy snowfall",
  95: "Thunderstorm",
  96: "Thunderstorm with slight hail",
  99: "Thunderstorm with heavy hail"
};

