import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { WeatherContainer, WeatherCard, WeatherDetails, IconWrapper } from './WeatherElements';
import { weatherCodeDescriptions } from '../../constants/constants';
import { FaTemperatureHigh, FaWind } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';

const Weather = ({ city, latitude, longitude }) => {
    const [weather, setWeather] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchWeather = async () => {
            try {
                const res = await axios.get(`https://api.open-meteo.com/v1/forecast`, {
                    params: {
                        latitude: latitude,
                        longitude: longitude,
                        current_weather: true,
                    },
                });

                setWeather(res.data.current_weather);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching weather data", error);
                setLoading(false);
            }
        };

        fetchWeather();
    }, [latitude, longitude]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <WeatherContainer>
            {weather && (
                <WeatherCard>
                    <h3>{city}</h3>
                    <p>{weatherCodeDescriptions[weather.weathercode] || "Unknown weather condition"}</p>
                    <WeatherDetails>
                        <p>
                            <IconWrapper>
                                <FaTemperatureHigh />
                            </IconWrapper>
                            {weather.temperature}°C
                        </p>
                        <p>
                            <IconWrapper>
                                <FaWind />
                            </IconWrapper>
                            {weather.windspeed} km/h
                        </p>
                    </WeatherDetails>
                </WeatherCard>
            )}
        </WeatherContainer>
    );
};

export default Weather;
