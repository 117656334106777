import React, { useEffect, useState } from 'react';
import Navbar from '../../components/NavBar';
import {
    LoginContainer,
    IconContainer,
    BackIcon,
    FormContainer,
    Form,
    FormInput,
    FormLabel,
    FormButton,
    FormWrapper,
    FormTitle,
    ErrorContainer,
} from './LoginElements';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CONNECTIONS } from '../../constants/constants';
import { setCredentials } from '../../slices/credentialsSlice';
import { Gradient } from '../../utils/animations/Gradient';
import { FaSpinner } from 'react-icons/fa';

const Login = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [emailInput, setEmailInput] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const gradient = new Gradient()
    const {id, token, email, name, surname, phone, type} = useSelector((state) => state.credentials);

    // useEffect(() => {
    //     if ((id !== "" || id !== null) && 
    //     (token !== "" || token !== null) && 
    //     (email !== "" || email !== null) && 
    //     (name !== "" || name !== null) && 
    //     (surname !== "" || surname !== null) &&
    //     (phone !== "" || phone !== null) &&
    //     (type !== "" || type !== null)
    //     ) {
    //         navigate('/dashboard');
    //     }
    // })

    useEffect(() => {
        gradient.initGradient('#gradient-canvas');
    }, []);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const handleChange = (e) => {
        const { id, value } = e.target;
        if (id === 'email') {
            setEmailInput(value);
        } else if (id === 'password') {
            setPassword(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        setError(null);

        try {

            var url = `${CONNECTIONS.SERVICE_URL_CA}/api/login`;

            var requestBody = {
                email: emailInput,
                password: password
            };

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            const data = await response.json();

            console.log(data);

            if (data.type == "admingamtooswater" || data.type == "supergamtooswater") {

                dispatch(setCredentials({
                    token: data.token,
                    email: data.email,
                    id: data.id,
                    name: data.name,
                    surname: data.surname,
                    phone: data.phone,
                    type: data.type,
                }));
                navigate('/dashboard');
            } else {
                setError('Login failed');
            }

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <canvas id="gradient-canvas" style={{
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%', 
                zIndex: -1
            }}></canvas>
            <LoginContainer>
                <FormContainer>
                    <FormWrapper>
                        <FormTitle>Login</FormTitle>
                        <Form onSubmit={handleSubmit}>
                            <FormLabel htmlFor='email'>Email</FormLabel>
                            <FormInput
                                type='email'
                                id='email'
                                value={emailInput}
                                onChange={handleChange}
                                required
                            />
                            <FormLabel htmlFor='password'>Password</FormLabel>
                            <FormInput
                                type='password'
                                id='password'
                                value={password}
                                onChange={handleChange}
                                required
                            />
                            {error && <ErrorContainer>{error}</ErrorContainer>}
                            <FormButton type='submit' disabled={loading}>
                                {loading ? (
                                    <>
                                        <FaSpinner className="spin" />
                                    </>
                                ) : (
                                    'Login'
                                )}
                            </FormButton>
                        </Form>
                    </FormWrapper>
                </FormContainer>
            </LoginContainer>
        </div>
    )
};

export default Login;
