import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const WeatherContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    animation: fadeIn 1s ease-out;
    
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

export const WeatherCard = styled.div`
    width: 100%;
    max-width: 400px;
    text-align: center;
    animation: scaleUp 0.5s ease-out;

    @keyframes scaleUp {
        0% {
            transform: scale(0.9);
        }
        100% {
            transform: scale(1);
        }
    }

    h3 {
        margin: 10px 0;
        font-size: 24px;
        color: ${COLORS.FIRST};
    }

    p {
        font-size: 18px;
        color: ${COLORS.BLACK};
    }
`;

export const WeatherDetails = styled.div`
    margin-top: 10px;
    font-size: 16px;
    color: ${COLORS.GRAY};
`;

export const IconWrapper = styled.div`
  display: inline-block;
  margin-right: 10px;
  color: ${COLORS.FIRST};
`;
